import React from 'react';
import NotFound from '../shared/NotFound/NotFound';
import { ThemeProvider } from '@mui/material';
import theme from '../app/theme';

function NotFoundPage() {
  const browser = typeof window !== 'undefined' && window;

  return <ThemeProvider theme={theme}>{browser && <NotFound />}</ThemeProvider>;
}

export default NotFoundPage;
