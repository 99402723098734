import React from 'react';

export default function SvgImage() {
  return (
    <svg
      width="422"
      height="422"
      viewBox="0 0 422 422"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M298 270H99.1973L57.3826 133.278C56.9826 131.973 56.8947 130.591 57.1261 129.245C57.3574 127.899 57.9016 126.626 58.7146 125.529C59.5277 124.432 60.587 123.541 61.8073 122.928C63.0275 122.315 64.3746 121.997 65.7401 122H246.278C248.147 122 249.967 122.599 251.469 123.711C252.972 124.823 254.078 126.388 254.624 128.175L298 270Z"
        fill="#4520AB"
      />
      <path
        d="M265.612 253H123.217C121.334 253 119.501 252.397 117.987 251.28C116.473 250.163 115.359 248.59 114.807 246.794L83.1927 143.656C82.9915 143.002 82.9471 142.309 83.063 141.635C83.1789 140.96 83.4519 140.322 83.86 139.772C84.2682 139.221 84.8 138.774 85.4128 138.467C86.0255 138.159 86.7021 137.999 87.3881 138H229.783C231.666 138 233.499 138.603 235.013 139.72C236.527 140.837 237.641 142.41 238.193 144.206L269.807 247.344C270.008 247.998 270.053 248.691 269.937 249.365C269.821 250.04 269.548 250.678 269.14 251.228C268.732 251.779 268.2 252.226 267.587 252.533C266.974 252.841 266.298 253.001 265.612 253V253Z"
        fill="#111111"
      />
      <path
        d="M99 270H365.228C367.024 270 368.747 270.726 370.017 272.019C371.287 273.312 372 275.066 372 276.895V286.105C372 287.934 371.287 289.688 370.017 290.981C368.747 292.274 367.024 293 365.228 293H105.772C104.883 293 104.002 292.822 103.18 292.475C102.359 292.129 101.612 291.621 100.983 290.981C100.355 290.34 99.8558 289.58 99.5155 288.744C99.1752 287.907 99 287.011 99 286.105V270Z"
        fill="#5C26F1"
      />
      <path
        d="M132 201L148 183"
        stroke="#99ADF9"
        strokeWidth="7.025"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M152 201L128 183"
        stroke="#99ADF9"
        strokeWidth="7.025"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M212 201L228 183"
        stroke="#99ADF9"
        strokeWidth="7.025"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M232 201L208 183"
        stroke="#99ADF9"
        strokeWidth="7.025"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M172 212C172 212 184.899 205.25 198 212"
        stroke="#99ADF9"
        strokeWidth="7.025"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M310.992 191C309.539 190.996 308.13 190.492 307 189.57C305.869 188.648 305.084 187.365 304.775 185.932C303.593 180.556 302.843 176.451 303.887 172.611C305.139 168.057 308.442 165.391 312.001 162.841C314.4 161.126 316.066 158.522 316.251 156.214C316.367 154.682 315.834 153.391 314.57 152.159C310.838 148.507 304.795 154.191 304.524 154.436C304.524 154.436 299.447 158.771 295.676 154.226C291.905 149.68 295.676 145.173 295.676 145.173C298.109 142.895 300.907 141.049 303.952 139.715C311.213 136.597 318.129 137.766 323.43 142.932C327.394 146.807 329.353 151.879 328.948 157.212C328.48 163.364 324.898 169.402 319.361 173.355C316.761 175.215 316.27 175.874 316.151 176.084C315.853 177.036 316.834 181.492 317.205 183.172C317.41 184.111 317.404 185.085 317.187 186.021C316.97 186.958 316.549 187.833 315.953 188.584C315.357 189.335 314.602 189.941 313.744 190.359C312.886 190.777 311.945 190.996 310.992 191V191Z"
        fill="#FFBC5B"
      />
      <path
        d="M313.5 213C317.642 213 321 209.642 321 205.5C321 201.358 317.642 198 313.5 198C309.358 198 306 201.358 306 205.5C306 209.642 309.358 213 313.5 213Z"
        fill="#FFBC5B"
      />
    </svg>
  );
}
