export default {
  background: {
    height: 'fit-content',
    backgroundColor: 'kit.background.lightpurple',
    position: 'relative',
    overflow: 'hidden',
    margin: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: {
        xs: 'calc( -0.65 * 195vw)',
        lg: 'calc( -0.75 * 32vw)',
      },
      left: { xs: 'calc(-145vw * 0.5)', lg: 'calc(-30vw * 0.5)' },
      width: { xs: '185vw', lg: '61vw' },
      height: { xs: '185vw', lg: '61vw' },
      border: 'solid 1px gray',
      borderRadius: '50%',
      backgroundColor: '#f7f6fd',
    },
  },
  page: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    zIndex: 1,
    display: 'flex',
    flexDirection: { xs: 'column-reverse', lg: 'row' },
    gap: 3,
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  image1: {
    width: { xs: 168, lg: '100%' },
    height: { xs: 168, lg: '100%' },
  },
  image2: {
    width: { xs: 100, lg: '100%' },
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 450,
  },
  text: {
    letterSpacing: '-0.07em',
    fontSize: { xs: 22, lg: 46 },
  },
  button: {
    margin: '0 auto',
    width: { xs: 247, lg: 291 },
  },
};
