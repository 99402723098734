import React from 'react';
import { Box, Typography } from '@mui/material';
import SvgImage from './SvgImage';
import styles from './styles';
import Svg404 from './Svg404';
import { Button, Link } from 'gatsby-theme-material-ui';

const LinkButton = ({ display }) => {
  return (
    <Link href="/" underline="none">
      <Button variant="kitPrimary" sx={{ ...styles.button, display }}>
        открыть главную страницу
      </Button>
    </Link>
  );
};
export default function NotFound() {
  return (
    <Box sx={styles.background}>
      <Box sx={styles.page}>
        <Box sx={styles.wrapper}>
          <LinkButton display={{ xs: 'flex', lg: 'none' }} />

          <Box sx={{ ...styles.image, ...styles.image1 }}>
            <SvgImage />
          </Box>
          <Box sx={styles.message}>
            <Box sx={{ ...styles.image, ...styles.image2 }}>
              <Svg404 />{' '}
            </Box>
            <Typography
              variant="desktopH1"
              align="center"
              color="kit.text.main"
              sx={styles.text}
            >
              Упс, страница не найдена
            </Typography>
            <LinkButton display={{ xs: 'none', lg: 'flex' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
