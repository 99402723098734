import React from 'react';

export default function Svg404() {
  return (
    <svg
      width="222"
      height="123"
      viewBox="0 0 222 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.6 78.9H45.6V95H33.8V78.9H4.2V69.3C14.4 53.8333 20.1667 39.0667 21.5 25H34.1C33.3667 32 31.4667 39.3 28.4 46.9C25.3333 54.5 21.8 61.4333 17.8 67.7H33.8V54H45.6V67.7H55.6V78.9Z"
        fill="#4520AB"
      />
      <path
        d="M217.6 78.9H207.6V95H195.8V78.9H166.2V69.3C176.4 53.8333 182.167 39.0667 183.5 25H196.1C195.367 32 193.467 39.3 190.4 46.9C187.333 54.5 183.8 61.4333 179.8 67.7H195.8V54H207.6V67.7H217.6V78.9Z"
        fill="#4520AB"
      />
      <path
        d="M89.1179 62.1153C89.1179 68.4148 86.224 72.3764 80.4361 74C86.224 75.6236 89.1179 79.5852 89.1179 85.8847V105.173C89.1179 107.576 89.5891 109.394 90.5313 110.628C91.4735 111.862 92.8195 112.479 94.5693 112.479H103.251V123H93.7617C88.3776 123 84.3396 121.571 81.6475 118.714C78.9555 115.856 77.6094 111.245 77.6094 104.881V84.1312C77.6094 82.5726 77.172 81.3711 76.2971 80.5268C75.4222 79.6826 74.1771 79.2604 72.5619 79.2604H66V68.7396H72.5619C74.1771 68.7396 75.4222 68.3174 76.2971 67.4732C77.172 66.6289 77.6094 65.4274 77.6094 63.8688V43.1193C77.6094 36.7548 78.9555 32.1438 81.6475 29.2863C84.3396 26.4288 88.3776 25 93.7617 25H103.251V35.5209H94.5693C92.8195 35.5209 91.4735 36.1378 90.5313 37.3718C89.5891 38.6057 89.1179 40.4241 89.1179 42.827V62.1153Z"
        fill="#4520AB"
      />
      <path
        d="M155 68.7396V79.2604H148.438C146.823 79.2604 145.578 79.6826 144.703 80.5268C143.828 81.3711 143.391 82.5726 143.391 84.1312V104.881C143.391 111.245 142.045 115.856 139.352 118.714C136.66 121.571 132.622 123 127.238 123H117.749V112.479H126.431C128.18 112.479 129.527 111.862 130.469 110.628C131.411 109.394 131.882 107.576 131.882 105.173V85.8847C131.882 79.5852 134.776 75.6236 140.564 74C134.776 72.3764 131.882 68.4148 131.882 62.1153V42.827C131.882 40.4241 131.411 38.6057 130.469 37.3718C129.527 36.1378 128.18 35.5209 126.431 35.5209H117.749V25H127.238C132.622 25 136.66 26.4288 139.352 29.2863C142.045 32.1438 143.391 36.7548 143.391 43.1193V63.8688C143.391 65.4274 143.828 66.6289 144.703 67.4732C145.578 68.3174 146.823 68.7396 148.438 68.7396H155Z"
        fill="#4520AB"
      />
    </svg>
  );
}
